@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

:root {
  --theme-color: #1D4EBB;
}

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #C2D2E6;
}

/* Firefox */
::-moz-selection {
  color: #FFFFFF;
  background: #ED6A53;
}

/* Chrome, Safari, Edge, Opera */
::selection {
  color: #FFFFFF;
  background: #ED6A53;
}

// /* Chrome, Safari, Edge, Opera */
// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

// /* Firefox */
// input[type=number] {
//   -moz-appearance: textfield;
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background-blue-clem {
  background-color: var(--theme-color);
}

.logo-style {
  max-height: 70px;
  display: inline-block;
}

.margin-bottom-jef {
  margin-bottom: 16px !important;
}

.contenitore-pagina { /* deprecata */
  min-height: calc(100vh - 263px);
}

.contenitore-pagina-faq-manuals {
  min-height: calc(100vh - 558px);
}

.contenitore-pagina-ticket-assistenza {
  min-height: calc(100vh - 289px);
}

.contenitore-pagina-preview-pagamento {
  min-height: calc(100vh - 329px);
}

.contenitore-pagina-pagamento {
  min-height: calc(100vh - 330px);
}

.contenitore-home {
  min-height: calc(100vh - 489px);
}

.contenitore-registrazione {
  min-height: calc(100vh - 289px);
}

.contenitore-ticket {
  min-height: calc(100vh - 415px);
}

.min-height-faq-manuals {
  min-height: 233px;
}

.header-min-height {
  min-height: 123px;
}

.documenti-height{
  min-height: 130px;
}

.page-loading {
  min-height: calc(100vh - 250px);
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  color: var(--theme-color);
  text-decoration: underline;
}
a:hover {
  color: var(--theme-color);
  text-decoration: none;
}

.link-footer {
  color: #FFFFFF !important;
  text-decoration: underline;
}
.link-footer:hover {
  opacity: 0.8 !important;
}

.footer {
  width: 100%;
  min-height: 140px;
  display: flex;
  align-items: center;
}

.buttons-style {
  background-color: var(--theme-color) !important;
  border-radius: 0px !important;
  font-weight: 300 !important;
  padding-top: 8px !important;
  width: 206px;
}
.buttons-style:hover {
  background-color: #1a45a5 !important;
}

.icon-button {
  margin-right: 0.75rem;
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.MuiInputBase-root {
  border-radius: 0px !important;
}

.box-form p {
  padding: 10px 0;
}

.info-registrazione {
  margin: 20px 0;
}

.ticket-progress {
  .MuiLinearProgress-bar1Determinate {
    transition: transform 1s linear;
  }
}

.login-rivenditori {
  display: flex;
  justify-content: end;
  align-items: center;
}

.ticket-h-table {
  height: 538px;
}

.box-pagamento {
  max-width: 90%;
  width: 520px;
  padding: 40px;
  background-color: #FFFFFF;
}